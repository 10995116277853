var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("q-page", { staticClass: "flex flex-center" }, [
        !_vm.ready
          ? _c("p", { staticClass: "text-faded" }, [_vm._v("Loading...")])
          : _c("p", { staticClass: "text-faded" }, [
              _vm._v(_vm._s(this.$route.params.id))
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }